
import Vue from "vue";
import Address from "@/models/address.model";
import CustomerAddressEdit, {
  AddressFormData,
  AddressFormErrors
} from "@/components/customer/CustomerAddressEdit.vue";
import customerService from "@/services/customer/customer.service";

export default Vue.extend<any, any, any, any>({
  name: "CustomerAddressCard",
  components: { CustomerAddressEdit },
  props: {
    deliveryAddress: {
      type: Address,
      default: () => new Address()
    },
    billingAddress: {
      type: Address,
      default: () => new Address()
    },
    loading: Boolean
  },
  data() {
    return {
      editEnabled: false,
      billingAddressTheSame: false,
      addressIsLoading: false,
      currentDeliveryAddress: null,
      currentBillingAddress: this.billingAddress,
      deliveryAddressForm: null,
      billingAddressForm: null,
      deliveryAddressErrors: {} as AddressFormErrors,
      billingAddressErrors: {} as AddressFormErrors
    };
  },
  watch: {
    deliveryAddress() {
      this.currentDeliveryAddress = this.deliveryAddress;
    },
    billingAddress() {
      this.currentBillingAddress = this.billingAddress;
    }
  },
  computed: {
    billingAddressDisplay(): Address {
      // use the delivery address if billing is empty
      if (this.isBillingAddressTheSame()) {
        return this.deliveryAddress;
      }
      return this.billingAddress;
    }
  },
  methods: {
    async updateAddress() {
      const customerId = parseInt(this.$route.params.customer_id, 10);
      this.addressIsLoading = true;

      try {
        if (this.billingAddressTheSame) {
          await customerService.updateCustomerDeliveryAddress(customerId, {
            first_line: this.deliveryAddressForm.firstLine,
            second_line: this.deliveryAddressForm.secondLine || "",
            city: this.deliveryAddressForm.city,
            county: this.deliveryAddressForm.county || "",
            postal_code: this.deliveryAddressForm.postcode
          });
        } else {
          await customerService.updateCustomerAddresses(customerId, {
            delivery_address: {
              first_line: this.deliveryAddressForm.firstLine,
              second_line: this.deliveryAddressForm.secondLine || "",
              city: this.deliveryAddressForm.city,
              county: this.deliveryAddressForm.county || "",
              postal_code: this.deliveryAddressForm.postcode
            },
            billing_address: {
              first_line: this.billingAddressForm.firstLine,
              second_line: this.billingAddressForm.secondLine || "",
              city: this.billingAddressForm.city,
              county: this.billingAddressForm.county || "",
              postal_code: this.billingAddressForm.postcode
            }
          });
        }
        await this.finalizeEdit();
      } catch (error) {
        const errors = error.response.data.resp.errors;
        await this.handleDeliveryAddressError(errors);
      }
    },
    async finalizeEdit() {
      const customerId = parseInt(this.$route.params.customer_id, 10);
      const updatedCustomer = await customerService.getCustomer(customerId);
      this.addressIsLoading = false;
      this.editEnabled = false;
      this.$emit("customer-updated", updatedCustomer);
    },
    async handleDeliveryAddressError(errors: Record<string, any>) {
      if (!errors.delivery_address && !errors.billing_address) {
        this.deliveryAddressErrors = {
          firstLine: errors.first_line,
          city: errors.city,
          postCode: errors.postal_code
        } as AddressFormErrors;
      } else {
        if (errors.delivery_address) {
          const deliveryErrors = errors.delivery_address;
          this.deliveryAddressErrors = {
            firstLine: deliveryErrors.first_line,
            city: deliveryErrors.city,
            postCode: deliveryErrors.postal_code
          } as AddressFormErrors;
        }
        if (errors.billing_address) {
          const billingErrors = errors.billing_address;
          this.billingAddressErrors = {
            firstLine: billingErrors.first_line,
            city: billingErrors.city,
            postCode: billingErrors.postal_code
          } as AddressFormErrors;
        }
      }
      this.addressIsLoading = false;
    },
    enableEdit() {
      this.editEnabled = true;
      this.billingAddressTheSame = this.isBillingAddressTheSame();
      this.deliveryAddressForm = this.mapDeliveryAddressToForm();
      this.billingAddressForm = this.mapBillingAddressToForm();
      this.deliveryAddressErrors = {};
      this.billingAddressErrors = {};
    },
    cancelEdit() {
      this.addressIsLoading = false;
      this.editEnabled = false;
      this.deliveryAddressErrors = {};
      this.billingAddressErrors = {};
    },
    isBillingAddressTheSame(): boolean {
      return Object.keys(this.billingAddress).length === 0 || !this.billingAddress;
    },
    mapDeliveryAddressToForm(): AddressFormData {
      return {
        firstLine: this.deliveryAddress?.firstLine,
        secondLine: this.deliveryAddress?.secondLine,
        city: this.deliveryAddress?.city,
        county: this.deliveryAddress?.county,
        postcode: this.deliveryAddress?.postalCode
      };
    },
    mapBillingAddressToForm(): AddressFormData {
      return {
        firstLine: this.billingAddress?.firstLine,
        secondLine: this.billingAddress?.secondLine,
        city: this.billingAddress?.city,
        county: this.billingAddress?.county,
        postcode: this.billingAddress?.postalCode
      };
    }
  }
});
