
import Vue from "vue";
import CustomerDetailsCard from "@/components/customer/CustomerDetailsCard.vue";
import CustomerAddressCard from "@/components/customer/CustomerAddressCard.vue";
import PetsTable from "@/components/pets/PetsTable.vue";
import Customer from "@/models/customer.model";
import customerService from "@/services/customer/customer.service";
import { SubscriptionStatus } from "@/models/subscription.model";
import Pet from "@/models/pet.v2.model";
import WithAppBar from "@/layouts/WithAppBar.vue";
import Alert, { AlertData } from "@/components/v2/Alert.vue";
import ConfirmModal from "@/components/v2/ConfirmModal.vue";
import petService from "@/services/pet.service";

export default Vue.extend<any, any, any, any>({
  name: "CustomerHubPage",
  inject: ["Api"],
  components: {
    Alert,
    WithAppBar,
    CustomerDetailsCard,
    CustomerAddressCard,
    PetsTable,
    ConfirmModal
  },
  data() {
    return {
      customer: new Customer(),
      loading: true,
      showNotification: false,
      ooccCheckoutLoading: false,
      notificationSuccess: false,
      petForEdit: null,
      showConfirmationModal: false,
      alertObject: this.$route.params.alert as AlertData
    };
  },
  mounted() {
    this.refreshCustomer();
  },
  methods: {
    refreshCustomer() {
      this.loading = true;
      customerService
        .getCustomer(parseInt(this.$route.params.customer_id, 10))
        .then(response => {
          this.customer = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async gotoAddNewPet() {
      await this.$router.push({ name: this.prototypeRouteNames.BLEND_CONFIGURATION });
    },
    async gotoEditPetPage(pet: Pet) {
      if (pet.currentSubscription?.status === SubscriptionStatus.ACTIVE) {
        this.petForEdit = pet;
        this.showConfirmationModal = true;
        return;
      }

      this.$router.push({
        name: this.prototypeRouteNames.BLEND_CONFIGURATION,
        params: { pet_id: `${pet.petId}`, customer_id: this.customer.customerId }
      });
    },
    async gotoNutritionalPlanPage(pet: Pet) {
      await this.$router.push({
        name: this.routeNames.NUTRITIONAL_PLAN,
        params: { pet_id: pet.petId, customer_id: this.customer.customerId }
      });
    },
    async goToPricingSummary() {
      await this.$router.push({
        name: this.routeNames.PRICE,
        params: { customer_id: this.customer.customerId }
      });
    },
    async deletePet(pet: Pet) {
      const ok = await petService.deletePet(pet.petId as number);
      if (ok) {
        this.refreshCustomer();
      }
    },
    async handleOutOfClinicCheckout() {
      try {
        this.ooccCheckoutLoading = true;
        const completePets = this.customer.pets?.filter(
          (pet: Pet) => pet.currentSubscription?.status === SubscriptionStatus.COMPLETE
        );
        // send oocc for the first complete pet, the oocc link contains pricing table for all pets
        await this.Api.initOutOfClinicCheckout(completePets[0].petId);
        this.notificationSuccess = true;
        this.showNotification = true;
      } catch (e) {
        this.showNotification = true;
        this.notificationSuccess = false;
      } finally {
        this.ooccCheckoutLoading = false;
      }
    },
    setCustomer(customer: Customer) {
      this.customer = customer;
    },
    editProfileModalBody: (petName: string): string =>
      `Making a change to ${petName}'s profile will pause their subscription. To reactivate, the customer must consent to the change in their new plan and price.`,
    editProfileModalTitle: (petName: string): string =>
      `Do you want to update ${petName}'s profile?`
  },
  computed: {
    checkoutDisabled(): boolean {
      return (
        this.customer.pets?.filter(
          (pet: Pet) => pet.currentSubscription?.status === SubscriptionStatus.COMPLETE
        ).length === 0
      );
    },
    hasActivePets(): boolean {
      if (!this.customer.pets) {
        return false;
      }
      const activePets = this.customer.pets?.filter(
        (pet: Pet) => pet.currentSubscription?.status === SubscriptionStatus.ACTIVE
      );
      return activePets.length > 0;
    }
  }
});
