
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Vue, { PropType } from "vue";

export interface AddressFormErrors {
  firstLine?: string | Array<string>;
  city?: string | Array<string>;
  postCode?: string | Array<string>;
}

export interface AddressFormData {
  firstLine: string;
  secondLine?: string;
  city: string;
  postcode: string;
  county?: string;
}

export default Vue.extend<any, any, any, any>({
  name: "CustomerAddressEdit",
  components: { LoadingSpinner },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    deliveryFormData: {
      type: Object as PropType<AddressFormData>,
      required: true
    },
    billingFormData: {
      type: Object as PropType<AddressFormData>,
      required: true
    },
    enableBillingAddress: Boolean,
    deliveryAddressErrors: {
      type: Object as PropType<AddressFormErrors>,
      default: () => ({})
    },
    billingAddressErrors: {
      type: Object as PropType<AddressFormErrors>,
      default: () => ({})
    }
  }
});
