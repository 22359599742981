
import Vue, { PropType } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export interface CustomerDetailsFormData {
  first?: string;
  last?: string;
  email?: string;
  mobilePhone?: string;
  canContact?: boolean;
}

export interface CustomerDetailsFormErrors {
  first?: string;
  last?: string;
  email?: string;
  mobile_phone?: string;
}

export default Vue.extend<any, any, any, any>({
  name: "CustomerDetailsEdit",
  components: {
    LoadingSpinner
  },
  props: {
    editFormData: {
      type: Object as PropType<CustomerDetailsFormData>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object as PropType<CustomerDetailsFormErrors>,
      default: () => ({})
    }
  }
});
