
import Vue, { PropType } from "vue";
import Pet from "@/models/pet.v2.model";
import { SubscriptionStatus } from "@/models/subscription.model";
import DeletePetModal from "@/components/BrandModal/DeletePetModal.vue";

export default Vue.extend({
  name: "PetsTable",
  components: { DeletePetModal },
  props: {
    pets: {
      type: Array as PropType<Array<Pet>>,
      default: () => new Array(new Pet())
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Breed",
          value: "breedsDisplay"
        },
        {
          text: "Age",
          value: "ageFullDisplay"
        },
        {
          text: "Diet type",
          value: "dietTypeDisplay"
        },
        {
          text: "Status",
          value: "currentSubscription.statusDisplay"
        },
        {
          text: "",
          value: "controls"
        }
      ]
    };
  },
  methods: {
    handleEdit(item: Pet) {
      this.$emit("handleEdit", item);
    },
    handleView(item: Pet) {
      this.$emit("handleView", item);
    },
    handleReactivate(item: Pet) {
      this.$emit("handleReactivate", item);
    },
    handleDelete(item: Pet) {
      this.$emit("deletePet", item);
    },
    isPetCompleted(pet: Pet): boolean {
      return (
        pet.currentSubscription?.status === SubscriptionStatus.ACTIVE ||
        pet.currentSubscription?.status === SubscriptionStatus.COMPLETE
      );
    },
    isPetActive(pet: Pet): boolean {
      return pet.currentSubscription?.status === SubscriptionStatus.ACTIVE;
    },
    isPetCancelled(pet: Pet): boolean {
      return pet.currentSubscription?.status === SubscriptionStatus.CANCELLED;
    }
  }
});
