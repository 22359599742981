
import Vue from "vue";

export interface AlertData {
  message: string;
  type: string;
}

export default Vue.extend({
  name: "Alert",
  props: {
    message: String,
    type: String,
    minHeight: {
      type: Number,
      default: 60
    }
  },
  computed: {
    iconName() {
      switch (this.type) {
        case "success": {
          return "mdi-check";
        }
        case "warning": {
          return "mdi-alert-outline";
        }
        case "error": {
          return "mdi-alert-circle-outline";
        }
        case "info": {
          return "mdi-information-outline";
        }
        default: {
          return "";
        }
      }
    }
  }
});
