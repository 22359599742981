
import Vue from "vue";
import Customer from "@/models/customer.model";
import customerService from "@/services/customer/customer.service";
import CustomerDetailsEdit, {
  CustomerDetailsFormData,
  CustomerDetailsFormErrors
} from "@/components/customer/CustomerDetailsEdit.vue";

export default Vue.extend<any, any, any, any>({
  name: "CustomerDetailsCard",
  components: { CustomerDetailsEdit },
  props: {
    customer: {
      type: Customer,
      required: true
    },
    loading: Boolean
  },
  data() {
    return {
      currentCustomer: this.customer,
      editEnabled: false,
      customerEditForm: {
        first: this.customer?.first,
        last: this.customer?.last,
        email: this.customer?.email,
        mobilePhone: this.customer?.mobilePhone,
        canContact: this.customer?.contactConsent
      } as CustomerDetailsFormData,
      editFormIsLoading: false,
      editFormErrors: {} as CustomerDetailsFormErrors
    };
  },
  watch: {
    customer(newCustomer: Customer) {
      this.currentCustomer = newCustomer;
    }
  },
  methods: {
    mapCustomerToCustomerEditForm(): CustomerDetailsFormData {
      return {
        first: this.currentCustomer?.first,
        last: this.currentCustomer?.last,
        email: this.currentCustomer?.email,
        mobilePhone: this.currentCustomer?.mobilePhone,
        canContact: this.currentCustomer?.contactConsent || false
      };
    },
    async updateCustomerData() {
      this.editFormIsLoading = true;
      try {
        await customerService.updateCustomerContactDetails(this.customer.customerId, {
          first: this.customerEditForm.first,
          last: this.customerEditForm.last,
          email: this.customerEditForm.email,
          mobile_phone: this.customerEditForm.mobilePhone,
          contact_consent: this.customerEditForm.canContact
        });
        await this.finalizeEdit();
      } catch (error) {
        const errors = error.response.data.resp.errors;
        this.handleEditError(errors);
      }
    },
    async finalizeEdit() {
      const updatedCustomer = await customerService.getCustomer(
        parseInt(this.$route.params.customer_id, 10)
      );
      this.editFormIsLoading = false;
      this.editEnabled = false;
      this.$emit("customer-updated", updatedCustomer);
    },
    handleEditError(errors: Record<string, any>) {
      if (errors) {
        this.editFormErrors = {
          first: errors.first,
          last: errors.last,
          email: errors.email,
          mobile_phone: errors.mobile_phone
        } as CustomerDetailsFormErrors;
      }
      this.editFormIsLoading = false;
    },
    enableEdit() {
      this.customerEditForm = this.mapCustomerToCustomerEditForm();
      this.editFormErrors = {};
      this.editEnabled = true;
    },
    cancelEdit() {
      this.editFormIsLoading = false;
      this.editEnabled = false;
      this.editFormErrors = {};
    }
  }
});
